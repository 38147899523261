<template>
  <codigo-qr />
</template>

<script>
import codigoQr from "@/components/codigoQr.vue";

export default {
  name: "App",
  components: {
    codigoQr,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3b3b3b;
  margin-top: 20px;
}
</style>
