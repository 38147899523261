<template>
  <div id="principal">
    <div v-if="!listoImprimir">
      <section class="hero is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered">
              <div class="column is-4-tablet is-4-desktop is-4-widescreen">
                <img alt="ACSA Logo" src="../assets/acsa_marge.png" />
                <div class="caja">
                  <!-- <img alt="ACSA Logo" src="../assets/logo-acsa-certificat.png" /> -->
                  <!-- <h3 v-if="!completadoQr">{{ mensajeError }}</h3> -->
                  <form
                    class="formulario"
                    v-on:input="actualizarQr"
                    @submit.prevent=""
                  >
                    <input
                      v-model="valores.nomCognom"
                      type="text"
                      class=""
                      required
                      placeholder="Nom i Cognom"
                      maxlength="35"
                    /><br />
                    <input
                      v-model="valores.direccio"
                      type="text"
                      class=""
                      required
                      placeholder="Adreça"
                      maxlength="35"
                    /><br />
                    <input
                      v-model="valores.telefon"
                      type="number"
                      class=""
                      required
                      placeholder="Telèfon"
                    /><br />
                    <input
                      v-model="valores.segonTelefon"
                      type="number"
                      class=""
                      placeholder="Segon telèfon"
                    />
                  </form>
                  <br />
                </div>
                <div class="caja-mensaje">
                  <br />
                  <!-- <img alt="ACSA Logo" src="../assets/logo-acsa-certificat.png" /> -->
                  Powered by

                  <a href="www.senianet.com">Sènia Informàtica</a><br /><br />
                </div>
                <button
                  class="ready-button"
                  v-if="completadoQr"
                  @click="revisarDatos"
                >
                  Descarrega
                </button>
                <button
                  class="not-ready-button"
                  v-if="!completadoQr && !listoImprimir"
                >
                  Descarrega
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="caja-imprimir" v-if="listoImprimir">
      
      <img alt="ACSA Logo" src="../assets/logo-acsa-certificat.png" />
      <div class="separador"></div> <br>
        <h3 class="title is-3">{{ valores.nomCognom }}</h3> 
        <h3 class="subtitle is-4">{{ valores.direccio }}</h3> 
        <h3 class="title is-5">{{ valores.telefon }}</h3> 
        <h3 class="subtitle is-6">{{ valores.segonTelefon }}</h3>

      <qrcode-vue :value="valorQrFinal" :size="sizeQr" level="H" />
    </div>
    
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import Swal from "sweetalert2";
import html2PDF from "jspdf-html2canvas";

export default {
  data() {
    return {
      mensajeError: "ff",
      listoImprimir: false,
      valorQrFinal: "",
      completadoQr: false,
      sizeQr: 150,
      valores: {
        nomCognom: "",
        direccio: "",
        telefon: "",
        segonTelefon: "unic telèfon",
      },
    };
  },
  components: {
    QrcodeVue,
  },
  methods: {
    revisarDatos() {
      Swal.fire({
        title: "¿ Son correctes les dades ?",
        html:
          `Nom i cognom: <br> <b>` +
          this.valores.nomCognom +
          `</b><br><br>Adreça: <br><b>` +
          this.valores.direccio +
          `</b><br><br>Telèfon: <br><b>` +
          this.valores.telefon +
          `</b><br><br>Segon telèfon: <br><b>` +
          this.valores.segonTelefon +
          `</b>`,
        showCancelButton: true,
        confirmButtonColor: "#a00bd6",
        cancelButtonColor: "#636363",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.listoImprimir = true;
          this.exportToPDF();
        }
      });
    },
    actualizarQr() {
      this.completadoQr = false;
      if (!this.valores.segonTelefon) {
        this.valores.segonTelefon = "unic telèfon";
      }
      this.mensajeError = "Completa els camps";
      if (
        this.valores.nomCognom &&
        this.valores.direccio &&
        this.valores.telefon &&
        this.valores.segonTelefon
      ) {
        if (
          this.valores.telefon > 99999999 &&
          this.valores.telefon < 1000000000
        ) {
          if (
            (this.valores.segonTelefon > 99999999 &&
              this.valores.segonTelefon < 1000000000) ||
            this.valores.segonTelefon == "unic telèfon"
          ) {
            this.completadoQr = true;
            this.valorQrFinal = JSON.stringify(this.valores);
          }
        } else {
          this.mensajeError = "Revisa els telefons";
        }
      }
    },
    exportToPDF() {
      setTimeout(function () {
        let page = document.getElementById("principal");
        html2PDF(page, {
          jsPDF: {
            format: "a4",
          },
          imageType: "image/jpeg",
          output: "./pdf/ACSA-Certif.pdf",
        });
      }, 2000);
    },
  },
};
</script>

<style scoped>
.caja {
  background-color: #f0f0f0;
  padding: 10px;
  margin: 10px;
  margin-bottom: 0px;
  border-radius: 5px 5px 0px 0px;
  align-content: center;
  justify-content: center;
  margin-top: 40px;
  box-shadow: 0px 2px 4px #888888;
}

p {
  font-weight: bold;
  color: #383838;
}

.caja-mensaje {
  background-color: #bdbdbd;
  padding: 10px;
  margin: 10px;
  border-radius: 0px 0px 5px 5px;
  align-content: center;
  justify-content: center;
  margin-top: 0px;
  box-shadow: 0px 2px 4px #888888;
}

input[type="text"],
input[type="number"] {
  border: none;
  border-bottom: 2px solid #000000;
  margin-top: 20px;
  font-size: 115%;
  width: 100%;
  height: 40px;
  border-radius: 3px;
}

input[type="text"]:focus,
input[type="number"]:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

form {
  margin: 10px;
}

.ready-button {
  display: block;
  margin: 10px auto;
  padding: 7px 13px;
  text-align: center;
  background: #a00db6;
  font-size: 20px;
  font-family: "Arial", sans-serif;
  color: #ffffff;
  white-space: nowrap;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 5px;
  width: 96%;
}

.not-ready-button {
  display: block;
  margin: 10px auto;
  padding: 7px 13px;
  text-align: center;
  background: #636363;
  font-size: 20px;
  font-family: "Arial", sans-serif;
  color: #ffffff;
  white-space: nowrap;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 5px;
  width: 96%;
  border: none;
}

.ready-button:active {
  vertical-align: top;
  padding: 8px 13px 6px;
}

.caja-imprimir {
  background-color: #ffffff;
  padding: 10px;
  margin: 10px;
  margin-bottom: 0px;
  align-content: center;
  justify-content: center;
  margin-top: 40px;
}

a {
  text-decoration: none;
}
.separador {
        border-style: solid;
      border-color: darkgrey;
      border-width: 0px 0px 1px 0px; /* 25px top, 10px right, 4px bottom and 35px left */
      padding: 5px;
}
</style>